import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";

import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  pass1: string;
  pass2: string;
}

export class User extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      pass1: "",
      pass2: "",
    };

    console.log(this.props.user);
  }

  handleExchangeToPassword = () => {
    if (this.state.pass1.length < 3 || this.state.pass1.length > 20) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.pass1 !== this.state.pass2) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호정보가 동일해야합니다 ",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.user_exchange_to_pass(this.state.pass1).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "비밀번호정보가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="b-modal __b-popup1__"
              style={{
                // backgroundColor: "rgb(0, 0, 0)",
                position: "fixed",
                inset: "0px",
                opacity: "0.7",
                cursor: "pointer",
              }}
            ></div>

            <div
              style={{
                //width: "1000px",
                height: "760px",
                background: "#151618",
                color: "#ffffff",
                fontFamily: "'Open Sans', helvetica, arial, sans-serif",
                zIndex: 1000001,
                position: "absolute",
                left: "50%",
                //marginLeft: "-500px",
              }}
              className="container"
            >
              <div className="row" style={{ marginTop: "60px" }}>
                <div className="panel-primary">
                  <div className="panel-body">
                    <form
                      name="joinForm"
                      id="joinForm"
                      className="form-horizontal"
                    >
                      <h2
                        style={{
                          color: "rgb(248, 186, 65)",
                          fontSize: "30px",
                          fontWeight: 400,
                        }}
                      >
                        마이페이지
                      </h2>
                      <div className="hr-line-dashed"></div>

                      <div className="form-group row">
                        <div className="col-xs-12">
                          <p
                            style={{
                              color: "rgb(248, 186, 65)",
                              fontWeight: "bold",
                            }}
                          >
                            보유머니 : {ConverMoeny(this.props.user.balance)}
                          </p>
                          <p
                            style={{
                              color: "rgb(248, 186, 65)",
                              fontWeight: "bold",
                            }}
                          >
                            보유콤프 : {ConverMoeny(this.props.user.rolling)}
                          </p>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-xs-6">
                          <label className="control-label">아이디</label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={this.props.user.id}
                          />
                        </div>
                        <div className="col-xs-6">
                          <label className="control-label">예금주명</label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={this.props.user.bankowner}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-xs-6">
                          <label className="control-label">회원은행</label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={this.props.user.bankname}
                          />
                        </div>
                        <div className="col-xs-6">
                          <label className="control-label">출금계좌번호</label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={this.props.user.banknum}
                          />
                        </div>
                      </div>

                      <div className="hr-line-dashed"></div>

                      <div className="form-group row">
                        <div className="col-xs-6">
                          <label className="control-label">새 비밀번호</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="새 비밀번호"
                            value={this.state.pass1}
                            onChange={(e: any) => {
                              this.setState({ pass1: e.target.value });
                            }}
                          />
                        </div>
                        <div className="col-xs-6"></div>
                      </div>
                      <div className="form-group row">
                        <div className="col-xs-6">
                          <label className="control-label">비밀번호 확인</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="비밀번호 확인"
                            value={this.state.pass2}
                            onChange={(e: any) => {
                              this.setState({ pass2: e.target.value });
                            }}
                          />
                        </div>
                        <div className="col-xs-6"></div>
                      </div>

                      <div className="form-group">
                        <div className="col-xs-12">
                          <button
                            className="btn col-xs-4 btn-lg btn-primary btn-block"
                            onClick={this.handleExchangeToPassword}
                            style={{ background: "#f8ba41" }}
                          >
                            비밀번호변경
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="pop_close"
                onClick={() => {
                  this.props.handleClose();
                }}
              ></div>
            </div>

            {/* <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>콤프</h5>
                      <span>COMP</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="form-container">
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>아이디</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.id}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>보유머니</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.balance)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>금고머니</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.point)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>예금주명</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.bankowner}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>회원은행</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.bankname}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>출금계좌번호</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={this.props.user.banknum}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>새 비밀번호</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="새 비밀번호"
                            value={this.state.pass1}
                            onChange={(e: any) => {
                              this.setState({ pass1: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>비밀번호 확인</span>
                        </div>
                        <div className="infos">
                          <input
                            placeholder="비밀번호 확인"
                            value={this.state.pass2}
                            onChange={(e: any) => {
                              this.setState({ pass2: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="btn-grp">
                        <button onClick={this.handleExchangeToPassword}>
                          <i className="fas fa-check-square"></i> 비밀번호변경
                        </button>
                        <button
                          className="gray"
                          data-dismiss="modal"
                          onClick={() => {
                            this.props.handleClose();
                          }}
                        >
                          <i className="fa fa-window-close"></i> 취소하기
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
