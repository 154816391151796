import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

import { BalanceService } from "../service/balance.service";
import { ConverMoeny } from "../utility/help";

import { ShareMenu } from "./share_menu";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
  point: string;
}

export class Point extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = {
      balance: "0",
      point: "0",
    };
  }

  handleChangeToBalance = () => {
    if (Number(this.state.balance) <= 0) {
      confirmAlert({
        title: "벨런스",
        message: "벨런스를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "벨런스",
      message: "보유머니를 입금 하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_balance_to_point(this.state.balance)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "벨런스",
                    message: "벨런스를 금고머니로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유머니를 초과하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  handleChangeToPoint = () => {
    if (Number(this.state.point) <= 0) {
      confirmAlert({
        title: "금고머니",
        message: "금고머니를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    confirmAlert({
      title: "금고머니",
      message: "금고머니를 출금하시겠습니까?",
      buttons: [
        {
          label: "확인",
          onClick: () => {
            this.balanceService
              .user_point_to_money(this.state.point)
              .then((data: any) => {
                if (data.status === "success") {
                  confirmAlert({
                    title: "금고머니",
                    message: "금고머니를 벨런스로 변경하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else if (data.status === "low_point") {
                  confirmAlert({
                    title: "벨런스",
                    message: "보유금액 또는 보유포인트를 확인해주세요 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                } else {
                  confirmAlert({
                    title: "금고머니",
                    message: "보유금을 초과 하였습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  });
                }
              });
          },
        },

        {
          label: "취소",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    console.log(this.props.user);
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="b-modal __b-popup1__"
              style={{
                // backgroundColor: "rgb(0, 0, 0)",
                position: "fixed",
                inset: "0px",
                opacity: "0.7",
                cursor: "pointer",
              }}
            ></div>

            <div
              style={{
                //width: "1000px",
                height: "760px",
                background: "#151618",
                color: "#ffffff",
                fontFamily: "'Open Sans', helvetica, arial, sans-serif",
                zIndex: 1000001,
                position: "absolute",
                left: "50%",
                //marginLeft: "-500px",
              }}
              className="container"
            >
              <div className="row" style={{ marginTop: "60px" }}>
                <div className="panel-primary">
                  <div className="panel-body">
                    <form
                      name="joinForm"
                      id="joinForm"
                      className="form-horizontal"
                    >
                      <h2
                        style={{
                          color: "rgb(248, 186, 65)",
                          fontSize: "30px",
                          fontWeight: 400,
                        }}
                      >
                        금고
                      </h2>

                      <div className="hr-line-dashed"></div>

                      <div className="form-group row">
                        <div className="col-xs-12">
                          <p
                            style={{
                              color: "rgb(248, 186, 65)",
                              fontWeight: "bold",
                            }}
                          >
                            보유머니 : {ConverMoeny(this.props.user.balance)}
                          </p>
                          <p
                            style={{
                              color: "rgb(248, 186, 65)",
                              fontWeight: "bold",
                            }}
                          >
                            금고머니 : {ConverMoeny(this.props.user.point)}
                          </p>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-xs-6">
                          <label className="control-label">금고입금</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                            value={this.state.balance}
                            onFocus={() => this.setState({ balance: "" })}
                            onChange={(e) =>
                              this.setState({
                                balance: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div
                          className="col-xs-6"
                          style={{ paddingTop: "10px" }}
                        >
                          <p>&nbsp;</p>
                          <button
                            className="btn-sm btn-primary"
                            type="button"
                            onClick={this.handleChangeToBalance}
                          >
                            입금하기
                          </button>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-xs-6">
                          <label className="control-label">금고출금</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                            value={this.state.point}
                            onFocus={() => this.setState({ point: "" })}
                            onChange={(e) =>
                              this.setState({
                                point: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div
                          className="col-xs-6"
                          style={{ paddingTop: "10px" }}
                        >
                          <p>&nbsp;</p>
                          <button
                            className="btn-sm btn-primary"
                            type="button"
                            onClick={this.handleChangeToPoint}
                          >
                            출금하기
                          </button>
                        </div>
                      </div>

                      <div className="hr-line-dashed"></div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="pop_close"
                onClick={() => {
                  this.props.handleClose();
                }}
              ></div>
            </div>

            {/* <div
              className="modal loginModal fade show display-on"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <img className="logo-modal" src="img/logo/logo-img.png" />
                  <div className="modal-header">
                    <div className="title text-left">
                      <h5>금고</h5>
                      <span>POINT</span>
                    </div>
                    <button
                      className="close-btn"
                      data-dismiss="modal"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="modal-menu">
                      <button className="mm-btn active">금고</button>
                      <button
                        className="mm-btn"
                        onClick={() => {
                          this.props.handleChange(popupView.comp); //콤프
                        }}
                      >
                        콤프
                      </button>
                    </div>
                    <div className="form-container">
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>보유머니</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.balance)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>금고머니</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            readOnly
                            value={ConverMoeny(this.props.user.point)}
                          />
                        </div>
                      </div>
                      <div className="form-group w-btn">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>금고입금</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            placeholder="0"
                            value={this.state.balance}
                            onFocus={() => this.setState({ balance: "" })}
                            onChange={(e) =>
                              this.setState({
                                balance: e.target.value,
                              })
                            }
                          />
                          <button onClick={this.handleChangeToBalance}>
                            <i className="fa fa-check-double"></i> 입금하기
                          </button>
                        </div>
                      </div>
                      <div className="form-group w-btn">
                        <div className="labels">
                          <span className="dot"></span>
                          <span>금고출금</span>
                        </div>
                        <div className="infos">
                          <input
                            type="text"
                            placeholder="0"
                            value={this.state.point}
                            onFocus={() => this.setState({ point: "" })}
                            onChange={(e) =>
                              this.setState({
                                point: e.target.value,
                              })
                            }
                          />
                          <button onClick={this.handleChangeToPoint}>
                            <i className="fa fa-check-double"></i> 출금하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </Popup>
    );
  }
}
