import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { confirmAlert } from "react-confirm-alert"; // Import
import Typography from "@material-ui/core/Typography";
import { UserService } from "../service/user.service";
import { ConvertDate, HelpStatus } from "../utility/help";

export enum helpView {
  none = "none",
  write = "write",
  view = "view",
  list = "list",
}

export enum popupView {
  none = "none",
  comp = "comp",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const service = new UserService();

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  const RenderRef = () => {
    if (ref != null && ref !== "") {
      return (
        <Box margin={1}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color={"secondary"}
          >
            답변
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: ref }}></div>
        </Box>
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              service.do_help_message_read(row._id);
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => {
            service.do_help_message_read(row._id);
            setOpen(!open);
          }}
          style={{ color: "#ffffff" }}
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {ConvertDate(row.regDate)}
        </CustomTableCell2>
        <CustomTableCell2 align="center" style={{ color: "#ffffff" }}>
          {HelpStatus(row.status)}
        </CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
            </Box>

            {RenderRef()}
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  helps: any;
  mode: string;

  title: string;
  contents: string;
  status: string;
  regdate: string;
  ref: string;
  index: string;
}

export class Help extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      helps: [],
      mode: helpView.list,
      title: "",
      contents: "",
      status: "",
      regdate: "",
      ref: "",
      index: "",
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });
  };

  handleSaveHelp = (title: string, contents: string) => {
    if (title == "") {
      confirmAlert({
        title: "고객센터",
        message: "타이틀을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }
    if (contents == "") {
      confirmAlert({
        title: "고객센터",
        message: "내용을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.handleGetNotices();
            },
          },
        ],
      });
      return;
    }

    this.userService.user_wirte_help(title, contents).then((date: any) => {
      if (date.status === "success") {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.handleGetNotices();
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });

    this.props.handleClose();
  };

  render() {
    let helps = this.state.helps;
    console.log(helps);

    const RenderRef = () => {
      if (this.state.ref != null && this.state.ref !== "") {
        return (
          <Box margin={1}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              color={"secondary"}
            >
              답변
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: this.state.ref }}></div>
          </Box>
        );
      }
    };

    const RenderList = () => {
      if (this.state.mode !== helpView.list) {
        return <></>;
      }
      return (
        <>
          <table
            className="table table-hover table-bordered"
            style={{ textAlign: "right", color: "white", fontSize: "14px" }}
          >
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "20%" }}>번호</th>
                <th style={{ textAlign: "center", width: "40%" }}>제목</th>
                <th style={{ textAlign: "center", width: "20%" }}>작성일</th>
                <th style={{ textAlign: "center", width: "20%" }}>상태</th>
              </tr>

              {helps.map((info: any, index: any) => (
                <>
                  <tr   onClick={() => {
                          service.do_help_message_read(info._id);
                          this.setState({
                            title: info.title,
                            contents: info.contents,
                            regdate: info.regdate,
                            status: info.status,
                            ref: info.ref != null ? info.ref.contents : "",
                            mode: helpView.view,
                            index: index + 1,
                          });
                        }}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td style={{ textAlign: "left", color: "#337ab7" }}>
                      <a
                        style={{ color: "#337ab7" }}
                      
                      >
                        {info.title}
                      </a>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {ConvertDate(info.regdate)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {HelpStatus(info.status)}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          <div className="hr-line-dashed"></div>

          <div>
            <button
              className="btn col-xs-4 btn-lg btn-primary btn-block"
              onClick={() => {
                this.setState({
                  mode: helpView.write,
                  title: "",
                  contents: "",
                  status: "",
                  regdate: "",
                  ref: "",
                  index: "",
                });
              }}
            >
              글쓰기
            </button>
          </div>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== helpView.view) {
        return <></>;
      }
      return (
        <>
          <table
            className="table table-bordered"
            style={{ textAlign: "right", color: "white", fontSize: "14px" }}
          >
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "20%" }}>번호</th>
                <th style={{ textAlign: "center", width: "40%" }}>제목</th>
                <th style={{ textAlign: "center", width: "20%" }}>작성일</th>
                <th style={{ textAlign: "center", width: "20%" }}>상태</th>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>{this.state.index}</td>
                <td style={{ textAlign: "center" }}>{this.state.title}</td>
                <td style={{ textAlign: "center" }}>
                  {ConvertDate(this.state.regdate)}
                </td>
                <td style={{ textAlign: "center" }}>
                  {HelpStatus(this.state.status)}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", height: "200px" }} colSpan={4}>
                  <div
                    style={{ overflow: "auto", width: "100%", height: "100%" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.contents }}
                    ></div>
                  </div>
                </td>
              </tr>
              {

                this.state.ref != null && this.state.ref !== "" && (
                  <tr>
                    <td style={{ textAlign: "left", height: "200px" }} colSpan={4}>
                      <div
                        style={{ overflow: "auto", width: "100%", height: "100%" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{ __html: this.state.ref }}
                        ></div>
                      </div>
                    </td>
                  </tr>
                )
              }
{/* 
              if (this.state.ref != null && this.state.ref !== "") {
        return (
          <Box margin={1}>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              color={"secondary"}
            >
              답변
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: this.state.ref }}></div>
          </Box>
        );
      } */}
            </tbody>
          </table>

          <div className="hr-line-dashed">
          </div>

          <div>
            <button
              className="btn col-xs-4 btn-lg btn-primary btn-block"
              onClick={() => {
                this.setState({
                  mode: helpView.list,
                });
              }}
            >
              목록
            </button>
          </div>
        </>
      );
    };

    const RenderWrite = () => {
      if (this.state.mode !== helpView.write) {
        return <></>;
      }

      return (
        <>
          <div className="form-group row">
            <div className="col-xs-12">
              <label className="control-label">제목</label>
              <input
                type="text"
                className="form-control"
                placeholder="제목을 입력 하세요."
                onChange={(e) =>
                  this.setState({
                    title: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-xs-12">
              <label className="control-label">내용</label>
              <textarea
                rows={4}
                cols={20}
                maxLength={300}
                className="form-control"
                placeholder="내용을 입력하세요"
                onChange={(e) =>
                  this.setState({
                    contents: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </div>

          <div className="hr-line-dashed"></div>

          <div>
            <button
              className="btn col-xs-4 btn-lg btn-primary btn-block"
              onClick={() => {
                this.handleSaveHelp(this.state.title, this.state.contents);
              }}
            >
              글쓰기
            </button>
            <button
              className="btn col-xs-4 btn-lg btn-primary btn-block"
              onClick={() => {
                this.setState({
                  mode: helpView.list,
                });
              }}
            >
              목록
            </button>
          </div>
        </>
      );
    };
    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              style={{
                //width: "1000px",
                height: "760px",
                background: "#151618",
                color: "#ffffff",
                fontFamily: "'Open Sans', helvetica, arial, sans-serif",
                zIndex: 1000001,
                position: "absolute",
                left: "50%",
                //marginLeft: "-500px",
              }}
              className="container"
            >
              <div className="row" style={{ marginTop: "60px" }}>
                <div className="panel-primary">
                  <div className="panel-body">
                    <form
                      name="joinForm"
                      id="joinForm"
                      className="form-horizontal"
                    >
                      <h2
                        style={{
                          color: "rgb(248, 186, 65)",
                          fontSize: "30px",
                          fontWeight: 400,
                        }}
                      >
                        고객센터
                      </h2>
                      <div className="hr-line-dashed"></div>

                      {RenderList()}
                      {RenderWrite()}
                      {RenderView()}

                      <div className="hr-line-dashed"></div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="pop_close"
                onClick={() => {
                  this.props.handleClose();
                }}
              ></div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
