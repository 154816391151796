import React, { Component } from "react";

interface Props {}
interface State {}

export class Footer extends Component<Props, State> {
  render() {
    return (
      <>
        <div id="sns_wrap" className="section" style={{ userSelect: "auto" }}>
          <span style={{ userSelect: "auto" }}>Our Social platform</span>
          <a href="#" style={{ userSelect: "auto" }}>
            <img src="/img/sns_facebook.png" style={{ userSelect: "auto" }} />
          </a>
          <a href="#" style={{ userSelect: "auto" }}>
            <img src="/img/sns_twitter.png" style={{ userSelect: "auto" }} />
          </a>
          <a href="#" style={{ userSelect: "auto" }}>
            <img src="/img/sns_instagram.png" style={{ userSelect: "auto" }} />
          </a>
        </div>

        <div id="footer_wrap" style={{ userSelect: "auto" }}>
          <div className="footer section" style={{ userSelect: "auto" }}>
            <h2
              className="ani"
              animate-effect="slideInUp"
              animate-duration="0.3s"
              animate-delay="0.1s"
              style={{ userSelect: "auto" }}
            >
              Contact Us
            </h2>

            <p
              className="ani"
              animate-effect="slideInUp"
              animate-duration="0.3s"
              animate-delay="0.2s"
              style={{ userSelect: "auto" }}
            >
              <strong style={{ userSelect: "auto" }}>
                Donec sed odio dui. Etiam porta sem malesuada magna mollis
                euismod.
              </strong>
              <br style={{ userSelect: "auto" }} />
              <br style={{ userSelect: "auto" }} />
              Consectetur adipisicing elit. Aut eaque, totam corporis laboriosam
              veritatis quis ad perspiciatis, totam corporis laboriosam
              veritatis,
              <br style={{ userSelect: "auto" }} />
              consectetur adipisicing elit quos non quis ad perspiciatis, totam
              corporis ea,
            </p>

            <a
              href="#"
              className="btn_sendmail ani"
              animate-effect="slideInUp"
              animate-duration="0.3s"
              animate-delay="0.3s"
              style={{ userSelect: "auto" }}
            >
              <img
                src="/img/icon_sendmail.png"
                style={{ userSelect: "auto" }}
              />{" "}
              send us E-mail
            </a>

            <ul
              className="card_icon ani"
              animate-effect="slideInUp"
              animate-duration="0.3s"
              animate-delay="0.4s"
              style={{ userSelect: "auto" }}
            >
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card1.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card2.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card3.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card4.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card5.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card6.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card7.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card8.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card9.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card10.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card11.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card12.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card13.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card14.png" style={{ userSelect: "auto" }} />
              </li>
              <li style={{ userSelect: "auto" }}>
                <img src="/img/card15.png" style={{ userSelect: "auto" }} />
              </li>
            </ul>

            <p
              className="copy ani"
              animate-effect="slideInUp"
              animate-duration="0.3s"
              animate-delay="0.5s"
              style={{ userSelect: "auto" }}
            >
              © 2020 언택트카지노
              <br style={{ userSelect: "auto" }} />
              consectetur adipisicing elit. Aut eaque, laboriosam veritatis,
              quos non quis ad perspiciatis, totam corporis ea, alias ut unde.
            </p>
          </div>
        </div>
      </>
    );
  }
}
