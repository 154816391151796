import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { UserService } from "../service/user.service";
import { SlotService } from "../service/slot.service";
import { Deposit } from "./deposit";
import { Withdraw } from "./withdraw";
import { Help } from "./help";
import { User } from "./user";
import { Notice } from "./notice";
import { Reg } from "./reg";
import { Login } from "./login";
import { Point } from "./point";
import { Comp } from "./comp";
import { Note } from "./note";
import { Companylist } from "./companylist";

export enum popupView {
  none = "none",
  deposit = "deposit",
  comp = "comp",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
  updateUser: () => any;
  //   session: any;
}

interface State {
  ID: string;
  PW: string;
  balance: number;
  postCount: number;
  noteCount: number;
  note: any;
  popupStatuses: string;
  expanded: string;
  notes: any;
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  casionos: any;
  evolgame: any;
  isOpen: boolean;
  gameCode: any;
  gameName: any;
}

export class Header extends Component<Props, State> {
  userService = new UserService();
  slotService = new SlotService();

  constructor(props: Props) {
    super(props);
    this.state = {
      balance: 0,
      ID: "",
      PW: "",
      postCount: 0,
      note: [],
      notes: [],
      popupStatuses: popupView.none,
      noteCount: 0,
      expanded: "",
      mode: gameView.casino,
      slots: [],
      slotgames: [],
      games: [],
      casionos: [],
      evolgame: null,
      isOpen: false,
      gameCode: "",
      gameName: "",
    };

    this.handleUpdateNote();

    setImmediate(async () => {
      try {
        for (;;) {
          this.handleUpdateNote();

          await sleep(10000);
        }
      } catch (ex) {}
    });
    // this.updateUser();
  }

  componentDidMount() {
    this.handleUpdateInBalance();

    this.slotService.getSlotSetting().then((s) => {
      if (s.status === "success") {
        this.setState({ slots: s.slot, mode: Mode.slot });
      }
    });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {
        const item: any = [];

        for (const casiono of Object.values(s.casiono) as any) {
          if (casiono.nameEn !== "Evolution") {
            item.push(casiono);
          }
        }

        this.setState({
          casionos: item,
        });
      }
    });
  }

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  handleUpdateInBalance = () => {
    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance ?? 0,
        });
        this.props.user.balance = data.balance ?? 0;
      } else {
      }
    });
  };

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        if (data.count > 0 && this.state.postCount === 0) {
          this.setState({
            postCount: data.count,
            note: data.note,
            popupStatuses: popupView.note,
          });
        } else if (this.state.popupStatuses !== popupView.note) {
          this.setState({
            postCount: 0,
            note: [],
          });
        }

        // if (this.state.postCount > 0) {
        //   this.setState({ popupStatuses: popupView.note });
        // }
      } else if (this.props.authenticated) {
        this.props.tryLoginOut();
      }
    });
  };

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleReadNote = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_read_note(id).then((data: any) => {
        if (data.status === "success") {
          let note = this.state.note.find((read: any) => read._id === id);
          if (note != null) {
            note.isRead = "y";
            this.setState({
              note: this.state.note,
            });
          }
        }
      });
    }
  };

  do_del_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_del_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  do_read_all_note = () => {
    if (this.props.authenticated == true) {
      this.userService.do_read_all_note().then((data: any) => {
        window.location.reload();
      });
    }
  };

  handleDel = (id: string) => {
    if (this.props.authenticated == true) {
      this.userService.do_del_note(id).then((data: any) => {
        if (data.status === "success") {
          this.setState({
            notes: data.note,
          });
          this.handleUpdateNote();
        }
      });
    }
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  changePopup = (value: any) => {
    this.setState({ popupStatuses: value });
  };

  render() {
    const user = this.props.user;
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.deposit) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Deposit
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
            updateUser={this.props.updateUser}
          ></Deposit>
        );
      }

      if (this.state.popupStatuses === popupView.withdraw) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Withdraw
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Withdraw>
        );
      }

      if (this.state.popupStatuses === popupView.comp) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Comp
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Comp>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      if (this.state.popupStatuses === popupView.note) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Note
            handleClosePopup={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            // handleDel={this.handleDel}
            // handleReadNote={this.handleReadNote}
            handleClose={this.handleClosePopup}
            note={this.state.note}
          ></Note>
        );
      }

      if (this.state.popupStatuses === popupView.point) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Point
            handleClose={this.handleClosePopup}
            user={this.props.user}
            handleChange={this.handleChangePopup}
            updateUser={this.props.updateUser}
          ></Point>
        );
      }

      if (this.state.popupStatuses === popupView.reg) {
        return (
          <Reg
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Reg>
        );
      }

      if (this.state.popupStatuses === popupView.login) {
        return (
          <Login
            handleClose={this.handleClosePopup}
            tryLogin={this.props.tryLogin}
            changePopup={this.changePopup}
            handleChange={this.handleChangePopup}
          ></Login>
        );
      }

      if (this.state.popupStatuses === popupView.help) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Help
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Help>
        );
      }
      if (this.state.popupStatuses === popupView.user) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <User
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            user={this.props.user}
          ></User>
        );
      }
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated == false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
          ></Companylist>
        );
      }

      return <div></div>;
    };

    const RendePopup = () => {
      // if (0 < this.state.postCount) {
      //   this.setState({ popupStatuses: popupView.note });
      // }

      // this.setState({ popupStatuses: popupView.note });

      return <div></div>;
    };

    const RenderNoteCount = () => {
      if (this.state.noteCount === 0) {
        return <></>;
      }

      return (
        <>
          <span className="count">{this.state.noteCount}</span>
        </>
      );
    };

    const RenderLogin = () => {
      if (authenticated === false) {
        return (
          <>
            <nav>
              <ul>
                <li>
                  <a
                    href="javascript:;"
                    className="btn_login"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.login });
                    }}
                  >
                    로그인
                  </a>
                </li>

                <li>
                  <a
                    href="javascript:;"
                    className="btn_regist"
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.reg });
                    }}
                  >
                    회원가입
                  </a>
                </li>
              </ul>
            </nav>
          </>
        );
      }

      return (
        <>
          <nav style={{ userSelect: "auto" }}>
            <ul style={{ userSelect: "auto" }}>
              <li style={{ userSelect: "auto" }}>
                <a
                  href="javascript:;"
                  className="btn_logout"
                  style={{ userSelect: "auto" }}
                  onClick={() => {
                    this.props.tryLoginOut();
                  }}
                >
                  로그아웃
                </a>
              </li>
            </ul>
          </nav>

          <div className="reg_info" style={{ userSelect: "auto" }}>
            <ul className="reg_btn" style={{ userSelect: "auto" }}>
              <li style={{ userSelect: "auto" }}>
                <a
                  href="javascript:;"
                  id="in_btn"
                  style={{ userSelect: "auto" }}
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.deposit }); //입금신청
                  }}
                >
                  입금신청
                </a>
              </li>
              <li style={{ userSelect: "auto" }}>
                <a
                  href="javascript:;"
                  id="out_btn"
                  style={{ userSelect: "auto" }}
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.withdraw }); //출금신청
                  }}
                >
                  출금신청
                </a>
              </li>

              <li style={{ userSelect: "auto" }}>
                <a
                  href="javascript:;"
                  id="pp_btn"
                  style={{ userSelect: "auto" }}
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.point }); //금고
                  }}
                >
                  금고
                </a>
              </li>
            </ul>
            <ul className="reg_btn" style={{ userSelect: "auto" }}>
              <li style={{ userSelect: "auto" }}>
                <a
                  href="javascript:;"
                  id="cc_btn"
                  style={{ userSelect: "auto" }}
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.comp }); //콤프
                  }}
                >
                  콤프
                </a>
              </li>

              <li style={{ userSelect: "auto" }}>
                <a
                  href="javascript:;"
                  id="join_edit_btn"
                  style={{ userSelect: "auto" }}
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.help }); //마이페이지
                  }}
                >
                  고객센터
                </a>
              </li>

              <li style={{ userSelect: "auto" }}>
                <a
                  href="javascript:;"
                  id="join_edit_btn"
                  style={{ userSelect: "auto" }}
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.user }); //마이페이지
                  }}
                >
                  마이페이지
                </a>
              </li>

              
            </ul>

            <ul className="reg_btn" style={{ userSelect: "auto" }}>
              <li style={{ userSelect: "auto" }}>
                <a
                  href="javascript:;"
                  className="notice"
                  style={{ userSelect: "auto" }}
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.notice }); //공지사항
                  }}
                >
                  공지사항
                </a>
              </li>
            </ul>

            <ul className="chip_info" style={{ userSelect: "auto" }}>
              <li style={{ userSelect: "auto" }}>
                <label style={{ userSelect: "auto" }}>보유머니</label>
                <strong style={{ userSelect: "auto" }}>
                  <span id="own_money" style={{ userSelect: "auto" }}>
                    {ConverMoeny(this.props.user.in_balance)}
                  </span>{" "}
                  원
                </strong>
              </li>

              <li style={{ userSelect: "auto" }}>
                <label style={{ userSelect: "auto" }}>보유콤프</label>
                <strong style={{ userSelect: "auto" }}>
                  {ConverMoeny(this.props.user.rolling)} 원
                </strong>
              </li>
              <li style={{ userSelect: "auto" }}>
                <label style={{ userSelect: "auto" }}>쪽지</label>
                <strong style={{ userSelect: "auto" }}>
                  <a
                    href="javascript:;"
                    className="link"
                    style={{ userSelect: "auto" }}
                    onClick={() => {
                      this.setState({ popupStatuses: popupView.note }); //쪽지
                    }}
                  >
                    {this.state.noteCount}
                  </a>
                </strong>
              </li>
            </ul>
          </div>
        </>
      );
    };

    console.log(this.props.user)
    return (
      <>
        <header id="header_wrap" style={{ userSelect: "auto" }}>
          <div id="header" style={{ userSelect: "auto" }}>
            <a
              className="logo"
              href="javascript:;"
              style={{ userSelect: "auto" }}
            >
              <img src="/img/logo.png" style={{ userSelect: "auto", width:'200px' }} />
            </a>
            {RenderLogin()}
          </div>
        </header>
        {RenderPopup()}
        {RendePopup()}
      </>
    );
  }
}
