import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { SlotService } from "../service/slot.service";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";
import { Notice } from "./notice";
import { Help } from "./help";
import { Companylist } from "./companylist";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
}

export enum gameView {
  none = "none",
  game = "game",
  slot = "slot",
  casino = "casino",
  slotlist = "slotlist",
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
}

interface State {
  mode: string;
  slots: any;
  slotgames: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  gameCode: any;
  gameName: any;
  evolgame: any;
  isOpen: boolean;
  popupStatuses: string;
  notices: any;
  widhdraws: any;
}

export class Contents extends Component<Props, State> {
  slotService = new SlotService();
  userService = new UserService();

  constructor(props: any) {
    super(props);
    this.state = {
      mode: gameView.slot,
      slots: [],
      slotgames: [],
      games: [],
      withdraws: [],
      deposits: [],
      casionos: [],
      notices: [],
      gameCode: "",
      gameName: "",
      isOpen: false,
      evolgame: null,
      popupStatuses: popupView.none,
      widhdraws: [],
    };
  }

  componentDidMount() {
    // this.slotService.getSlotSetting().then((s) => {
    //   if (s.status === "success") {
    //     console.log(s.slot);
    //     this.setState({ slots: s.slot, mode: Mode.slot });
    //   }
    // });

    this.slotService.getCasinoSetting().then((s) => {
      if (s.status === "success") {


        this.setState({
          casionos: s.casiono,
        });
      }
    });

    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.notices });
      }
    });

    var today = new Date();
    var yesterday = new Date(today.setDate(today.getDate() - 1));
    var yesterdayStr =
      yesterday.getFullYear() +
      "년 " +
      (yesterday.getMonth() + 1) +
      "월 " +
      yesterday.getDate() +
      "일";

    let tmp: any;
    tmp = [
      { id: "***ths", price: "86,000,000 원", date: yesterdayStr },
      { id: "***jvd", price: "83,000,000 원", date: yesterdayStr },
      { id: "**vhr", price: "82,000,000 원", date: yesterdayStr },
      { id: "***vdh", price: "80,000,000 원", date: yesterdayStr },
      { id: "***df", price: "78,000,000 원", date: yesterdayStr },
    ];

    this.setState({ widhdraws: tmp });
  }

  handleClosePopup = () => {
    this.setState({ popupStatuses: popupView.none });
  };

  handleChangePopup = (viewName: string) => {
    this.setState({ popupStatuses: viewName });
  };

  handleOpenSlot = (code: string, company: string) => {
    this.slotService.OpenSlot(code, company).then((data: any) => {
      this.setState({ isOpen: false });
      if (data.status === "success") {
        let timn = new Date().getTime();
        window.open(data.gameUrl, "Data", "height=800,width=1400");
      }
    });
  };

  // handleGame = (name: string) => {
  //   this.slotService.get_slot_by_company(name).then((s: any) => {
  //     if (s.status === "success") {
  //       this.setState({ games: s.games, mode: Mode.game });
  //     }
  //   });
  // };

  handleSlotGames = (gameCode: string) => {
    this.slotService.get_slot_by_company(gameCode).then((s: any) => {
      if (s.status === "success") {
        this.setState({ slotgames: s.games });
      }
    });
  };

  RenderSlot = () => {
    return (
      <>
        <ul className="game">
          {this.state.slots.map((info: any, index: any) => (
            <li
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (this.props.authenticated == false) {
                  confirmAlert({
                    title: "로그인",
                    message: "로그인 이후 이용가능한 기능입니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          this.setState({ popupStatuses: popupView.none });
                        },
                      },
                    ],
                  });
                  return;
                }

                if (info.used === "y") {
                  this.setState({ gameCode: info.code });
                  this.setState({ gameName: info.nameKo });
                  this.setState({ popupStatuses: popupView.game });
                  this.setState({ mode: gameView.slotlist });
                  this.handleSlotGames(info.code);
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <div className="con">
                <img src={info.mobileImg} />
                <div className="title">
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "25px",
                      fontWeight: "bold",
                      marginTop: "120px",
                    }}
                  >
                    {info.nameKo}
                  </p>
                </div>
                <a href="javascript:;">
                  <img src="/img/btn_game.png" />
                </a>
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  };

  RenderSlotList = () => {
    if (this.state.mode !== gameView.slotlist) {
      return <></>;
    }
    return (
      <>
        <main className="page-content sub-page">
          <section className="gamelist-section">
            <div className="container">
              <div className="game-menu">
                {/* <a href="javascript:;" className="menu-link hot-link">
                  <div className="img-cont">
                    <img src="/img/icon/hot-tag.png" />
                  </div>
                  <div className="text-cont">
                    <span>HOT GAMES</span>
                  </div>
                </a> */}

                {this.state.slots.map((info: any, index: any) => (
                  <a
                    href="javascript:;"
                    className="menu-link"
                    key={`key${info.nameEn}kkk`}
                    onClick={() => {
                      if (this.props.authenticated == false) {
                        confirmAlert({
                          title: "로그인",
                          message: "로그인 이후 이용가능한 기능입니다",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {
                                this.setState({
                                  popupStatuses: popupView.none,
                                });
                              },
                            },
                          ],
                        });
                        return;
                      }

                      if (info.used === "y") {
                        // this.setState({ gameCode: info.code });
                        // this.setState({ gameName: info.nameKo });
                        // this.setState({ popupStatuses: popupView.game });
                        // this.setState({ mode: gameView.slotlist });
                        // this.handleSlotGames(info.code);
                        this.setState({ gameCode: info.code });
                        this.setState({ gameName: info.nameKo });
                        this.setState({ popupStatuses: popupView.game });
                      } else {
                        confirmAlert({
                          title: "점검중",
                          message: "현재 해당게임은 점검중입니다 .",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {},
                            },
                          ],
                        });
                      }
                    }}
                  >
                    <div className="img-cont">
                      {/* <img src="/img/slot-logo/aristocrat.png" /> */}
                      {info.nameEn}
                    </div>
                    <div className="text-cont">
                      <span>{info.nameKo}</span>
                    </div>
                  </a>
                ))}
              </div>
              <div className="game-list">
                {this.state.slotgames.map((info: any, index: any) => (
                  <a
                    href="javascript:;"
                    className="game-btn"
                    onClick={() => {
                      this.handleOpenSlot(info.code, info.gameCompany);
                    }}
                  >
                    <div className="main-cont">
                      <img className="main-img" src={`${info.imgUrl}`} />
                      <button className="play-btn">
                        <i className="fa fa-play" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="footer">
                      <span>{info.nameKo}</span>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </section>
        </main>
      </>
    );
  };

  RenderCasino = () => {
    let info1: any;
    let info2: any;
    let info3: any;
    let info4: any;

    this.state.casionos.map((info: any, index: any) => {
      if (index === 0) {
        info1 = info;
      } else if (index === 1) {
        info2 = info;
      } else if (index === 2) {
        info3 = info;
      } else if (index === 3) {
        info4 = info;
      }
    });

    return (
      <>
        <div id="viz_pc">
          <div id="panel_wrap">
            <ul className="panel">
              <li id="tab1" style={{ display: "block" }}>
                <a
                  href="javascript:;"
                  key={`key${info1 !== undefined ? info1.nameEn : ""}kkk`}
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              this.setState({ popupStatuses: popupView.none });
                            },
                          },
                        ],
                      });
                      return;
                    }

                    if ((info1 !== undefined ? info1.used : "") === "y") {
                      this.handleOpenSlot(
                        info1 !== undefined ? info1.code : "",
                        info1 !== undefined ? info1.gameCompany : ""
                      );
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  <h1 style={{ color: "#9000ff" }}>
                    {info1 !== undefined ? info1.nameKo : ""}
                  </h1>
                  <p style={{ margin: "0 0 20px 0" }}>
                    한번도 경험하지 못한 리얼 호텔 바카라
                  </p>
                  <h2 style={{ color: "#9000ff" }}>
                    7성급호텔 실제 배팅!
                    <br />
                    24시간 대리배팅!
                  </h2>
                  <p>칩을 놓고 가져오는 진짜 바카라!</p>
                  <div
                    className="join"
                    style={{ color: "#9000ff", border: "solid 3px #9000ff" }}
                  >
                    Join SpeedGame
                  </div>
                </a>
                <img src="/img/slide1_bg.jpg" className="img" />
                <div className="planet">
                  <img src="/img/slide1_planet.png" />
                </div>
                <div className="lighting">
                  <img src="/img/slide1_lighting.png" />
                </div>
              </li>
              <li id="tab2" style={{ display: "none" }}>
                <a
                  href="javascript:;"
                  key={`key${info2 !== undefined ? info2.nameEn : ""}kkk`}
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              this.setState({ popupStatuses: popupView.none });
                            },
                          },
                        ],
                      });
                      return;
                    }

                    if ((info2 !== undefined ? info2.used : "") === "y") {
                      this.handleOpenSlot(
                        info2 !== undefined ? info2.code : "",
                        info2 !== undefined ? info2.gameCompany : ""
                      );
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  <h1 style={{ color: "#dfad30" }}>
                    {info2 !== undefined ? info2.nameKo : ""}
                  </h1>
                  <p style={{ margin: "0 0 20px 0" }}>카가얀의 새로운 이름</p>
                  <h2 style={{ color: "#dfad30" }}>
                    새롭게 시작하는 리얼 카지노
                  </h2>
                  <p>
                    뉴에이지 카지노
                    <br /> 언택트카지노
                  </p>
                  <div
                    className="join"
                    style={{ color: "#dfad30", border: "solid 3px #dfad30" }}
                  >
                    Join AsiaCasino
                  </div>
                </a>
                <img src="/img/slide2.jpg" className="img" />
              </li>
              <li id="tab3" style={{ display: "none" }}>
                <a
                  href="javascript:;"
                  key={`key${info3 !== undefined ? info3.nameEn : ""}kkk`}
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              this.setState({ popupStatuses: popupView.none });
                            },
                          },
                        ],
                      });
                      return;
                    }

                    if ((info3 !== undefined ? info3.used : "") === "y") {
                      this.handleOpenSlot(
                        info3 !== undefined ? info3.code : "",
                        info3 !== undefined ? info3.gameCompany : ""
                      );
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  <h1 style={{ color: "#7cf700" }}>
                    {info3 !== undefined ? info3.nameKo : ""}
                  </h1>
                  <p style={{ margin: "0 0 20px 0" }}>명품 카지노의 시작</p>
                  <h2 style={{ color: "#7cf700" }}>사라지지 않는 명성</h2>
                  <p>Build your dream</p>
                  <div
                    className="join"
                    style={{ color: "#7cf700", border: "solid 3px #7cf700" }}
                  >
                    Join Evolution
                  </div>
                </a>
                <img src="/img/slide3.jpg" className="img" />
              </li>
              <li id="tab4" style={{ display: "none" }}>
                <a
                  href="javascript:;"
                  key={`key${info4 !== undefined ? info4.nameEn : ""}kkk`}
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              this.setState({ popupStatuses: popupView.none });
                            },
                          },
                        ],
                      });
                      return;
                    }

                    if ((info4 !== undefined ? info4.used : "") === "y") {
                      this.handleOpenSlot(
                        info4 !== undefined ? info4.code : "",
                        info4 !== undefined ? info4.gameCompany : ""
                      );
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  <h1 style={{ color: "#f26522" }}>
                    {info4 !== undefined ? info4.nameKo : ""}
                  </h1>
                  <p style={{ margin: "0 0 20px 0" }}>클래식 그랜드 라이브</p>
                  <h2 style={{ color: "#f26522" }}>
                    전통과 신뢰, 넘버원 카지노
                  </h2>
                  <p>멀티 배팅 바카라의 시작</p>
                  <div
                    className="join"
                    style={{ color: "#f26522", border: "solid 3px #f26522" }}
                  >
                    Join Oriental
                  </div>
                </a>
                <img src="/img/slide4.jpg" className="img" />
              </li>
            </ul>
          </div>

          <div className="tab_wrap">
            <ul className="tab">
              <li>
                <a title="#tab1" className="selected">
                  <h1 style={{ color: "#9000ff" }}>
                    {info1 !== undefined ? info1.nameKo : ""}{" "}
                  </h1>
                  <p style={{ margin: "0 0 12px 0" }}>
                    한번도 경험하지 못한 리얼 호텔 바카라
                  </p>
                  <h2 style={{ color: "#9000ff", height: "60px" }}>
                    7성급호텔 실제 배팅!
                    <br /> 24시간 대리배팅!
                  </h2>
                  <p style={{ margin: "0 0 30px 0" }}>
                    칩을 놓고 가져오는 진짜 바카라!
                  </p>
                  <div
                    className="join"
                    style={{
                      color: "#9000ff",
                      border: "solid 3px #9000ff",
                      cursor: "pointer",
                    }}
                    key={`key${info1 !== undefined ? info1.nameEn : ""}kkk`}
                    onClick={() => {
                      if (this.props.authenticated == false) {
                        confirmAlert({
                          title: "로그인",
                          message: "로그인 이후 이용가능한 기능입니다",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {
                                this.setState({
                                  popupStatuses: popupView.none,
                                });
                              },
                            },
                          ],
                        });
                        return;
                      }

                      if ((info1 !== undefined ? info1.used : "") === "y") {
                        this.handleOpenSlot(
                          info1 !== undefined ? info1.code : "",
                          info1 !== undefined ? info1.gameCompany : ""
                        );
                      } else {
                        confirmAlert({
                          title: "점검중",
                          message: "현재 해당게임은 점검중입니다 .",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {},
                            },
                          ],
                        });
                      }
                    }}
                  >
                    Join SpeenGame
                  </div>
                </a>
              </li>
              <li>
                <a title="#tab2">
                  <h1 style={{ color: "#dfad30" }}>
                    {info2 !== undefined ? info2.nameKo : ""}
                  </h1>
                  <p style={{ margin: "0 0 12px 0" }}>카가얀의 새로운 이름</p>
                  <h2 style={{ color: "#dfad30" }}>
                    새롭게 시작하는 리얼 카지노
                  </h2>
                  <p style={{ margin: "0 0 20px 0", height: "60px" }}>
                    뉴에이지 카지노
                    <br />
                    언택트카지노
                  </p>
                  <div
                    className="join"
                    style={{
                      color: "#dfad30",
                      border: "solid 3px #dfad30",
                      cursor: "pointer",
                    }}
                    key={`key${info2 !== undefined ? info2.nameEn : ""}kkk`}
                    onClick={() => {
                      if (this.props.authenticated == false) {
                        confirmAlert({
                          title: "로그인",
                          message: "로그인 이후 이용가능한 기능입니다",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {
                                this.setState({
                                  popupStatuses: popupView.none,
                                });
                              },
                            },
                          ],
                        });
                        return;
                      }

                      if ((info2 !== undefined ? info2.used : "") === "y") {
                        this.handleOpenSlot(
                          info2 !== undefined ? info2.code : "",
                          info2 !== undefined ? info2.gameCompany : ""
                        );
                      } else {
                        confirmAlert({
                          title: "점검중",
                          message: "현재 해당게임은 점검중입니다 .",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {},
                            },
                          ],
                        });
                      }
                    }}
                  >
                    Join AsiaCasino
                  </div>
                </a>
              </li>
              <li>
                <a title="#tab3">
                  <h1 style={{ color: "#7cf700" }}>
                    {info3 !== undefined ? info3.nameKo : ""}
                  </h1>
                  <p style={{ margin: "0 0 12px 0" }}>명품 카지노의 시작</p>
                  <h2 style={{ color: "#7cf700" }}>사라지지 않는 명성</h2>
                  <p style={{ margin: "0 0 20px 0", height: "60px" }}>
                    Build your dream
                  </p>
                  <div
                    className="join"
                    style={{
                      color: "#7cf700",
                      border: "solid 3px #7cf700",
                      cursor: "pointer",
                    }}
                    key={`key${info3 !== undefined ? info3.nameEn : ""}kkk`}
                    onClick={() => {
                      if (this.props.authenticated == false) {
                        confirmAlert({
                          title: "로그인",
                          message: "로그인 이후 이용가능한 기능입니다",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {
                                this.setState({
                                  popupStatuses: popupView.none,
                                });
                              },
                            },
                          ],
                        });
                        return;
                      }

                      if ((info3 !== undefined ? info3.used : "") === "y") {
                        this.handleOpenSlot(
                          info3 !== undefined ? info3.code : "",
                          info3 !== undefined ? info3.gameCompany : ""
                        );
                      } else {
                        confirmAlert({
                          title: "점검중",
                          message: "현재 해당게임은 점검중입니다 .",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {},
                            },
                          ],
                        });
                      }
                    }}
                  >
                    Join Evolution
                  </div>
                </a>
              </li>
              <li>
                <a title="#tab4">
                  <h1 style={{ color: "#f26522" }}>
                    {info4 !== undefined ? info4.nameKo : ""}
                  </h1>
                  <p style={{ margin: "0 0 12px 0" }}>클래식 그랜드 라이브</p>
                  <h2 style={{ color: "#f26522" }}>
                    전통과 신뢰, 넘버원 카지노
                  </h2>
                  <p style={{ margin: "0 0 20px 0", height: "60px" }}>
                    멀티 배팅 바카라의 시작
                  </p>
                  <div
                    className="join"
                    style={{
                      color: "#f26522",
                      border: "solid 3px #f26522",
                      cursor: "pointer",
                    }}
                    key={`key${info4 !== undefined ? info4.nameEn : ""}kkk`}
                    onClick={() => {
                      if (this.props.authenticated == false) {
                        confirmAlert({
                          title: "로그인",
                          message: "로그인 이후 이용가능한 기능입니다",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {
                                this.setState({
                                  popupStatuses: popupView.none,
                                });
                              },
                            },
                          ],
                        });
                        return;
                      }

                      if ((info4 !== undefined ? info4.used : "") === "y") {
                        this.handleOpenSlot(
                          info4 !== undefined ? info4.code : "",
                          info4 !== undefined ? info4.gameCompany : ""
                        );
                      } else {
                        confirmAlert({
                          title: "점검중",
                          message: "현재 해당게임은 점검중입니다 .",
                          buttons: [
                            {
                              label: "확인",
                              onClick: () => {},
                            },
                          ],
                        });
                      }
                    }}
                  >
                    Join Oriental
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  };

  RenderCasinoMobile = () => {
    let info1: any;
    let info2: any;
    let info3: any;
    let info4: any;

    this.state.casionos.map((info: any, index: any) => {
      if (index === 0) {
        info1 = info;
      } else if (index === 1) {
        info2 = info;
      } else if (index === 2) {
        info3 = info;
      } else if (index === 3) {
        info4 = info;
      }
    });

    return (
      <>
        <div id="viz_mobile">
          <ul>
            <li>
              <a href="javascript:;">
                <h1 style={{ color: "#9000ff" }}>
                  {info1 !== undefined ? info1.nameKo : ""}{" "}
                </h1>
                <p style={{ margin: "0 0 20px 0" }}>
                  한번도 경험하지 못한 리얼 호텔 바카라
                </p>
                <h2 style={{ color: "#9000ff" }}>
                  7성급호텔 실제 배팅!
                  <br />
                  24시간 대리배팅!
                </h2>
                <p>칩을 놓고 가져오는 진짜 바카라!</p>
                <div
                  className="join"
                  style={{
                    color: "#9000ff",
                    border: "solid 3px #9000ff",
                    cursor: "pointer",
                  }}
                  key={`key${info1 !== undefined ? info1.nameEn : ""}kkk`}
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              this.setState({ popupStatuses: popupView.none });
                            },
                          },
                        ],
                      });
                      return;
                    }

                    if ((info1 !== undefined ? info1.used : "") === "y") {
                      this.handleOpenSlot(
                        info1 !== undefined ? info1.code : "",
                        info1 !== undefined ? info1.gameCompany : ""
                      );
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  Join SpeedGame
                </div>
              </a>
              <img src="/img/slide1_bg.jpg" className="img" />
              <div className="planet">
                <img src="/img/slide1_planet.png" />
              </div>
              <div className="lighting">
                <img src="/img/slide1_lighting.png" />
              </div>
            </li>
            <li>
              <a href="javascript:;">
                <h1 style={{ color: "#dfad30" }}>
                  {info2 !== undefined ? info2.nameKo : ""}
                </h1>
                <p style={{ margin: "0 0 20px 0" }}>카가얀의 새로운 이름</p>
                <h2 style={{ color: "#dfad30" }}>
                  새롭게 시작하는 리얼 카지노
                </h2>
                <p>
                  뉴에이지 카지노
                  <br />
                  언택트카지노
                </p>
                <div
                  className="join"
                  style={{
                    color: "#dfad30",
                    border: "solid 3px #dfad30",
                    cursor: "pointer",
                  }}
                  key={`key${info2 !== undefined ? info2.nameEn : ""}kkk`}
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              this.setState({
                                popupStatuses: popupView.none,
                              });
                            },
                          },
                        ],
                      });
                      return;
                    }

                    if ((info2 !== undefined ? info2.used : "") === "y") {
                      this.handleOpenSlot(
                        info2 !== undefined ? info2.code : "",
                        info2 !== undefined ? info2.gameCompany : ""
                      );
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  Join AsiaCasino
                </div>
              </a>
              <img src="/img/slide2.jpg" className="img" />
            </li>
            <li>
              <a href="javascript:;">
                <h1 style={{ color: "#7cf700" }}>
                  {info3 !== undefined ? info3.nameKo : ""}
                </h1>
                <p style={{ margin: "0 0 20px 0" }}>명품 카지노의 시작</p>
                <h2 style={{ color: "#7cf700" }}>사라지지 않는 명성</h2>
                <p>Build your drean</p>
                <div
                  className="join"
                  style={{
                    color: "#7cf700",
                    border: "solid 3px #7cf700",
                    cursor: "pointer",
                  }}
                  key={`key${info3 !== undefined ? info3.nameEn : ""}kkk`}
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              this.setState({
                                popupStatuses: popupView.none,
                              });
                            },
                          },
                        ],
                      });
                      return;
                    }

                    if ((info3 !== undefined ? info3.used : "") === "y") {
                      this.handleOpenSlot(
                        info3 !== undefined ? info3.code : "",
                        info3 !== undefined ? info3.gameCompany : ""
                      );
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  Join Evolution
                </div>
              </a>
              <img src="/img/slide3.jpg" className="img" />
            </li>
            <li>
              <a href="javascript:;">
                <h1 style={{ color: "#f26522" }}>
                  {info4 !== undefined ? info4.nameKo : ""}
                </h1>
                <p style={{ margin: "0 0 20px 0" }}>클래식 그랜드 라이브</p>
                <h2 style={{ color: "#f26522" }}>전통과 신뢰, 넘버원 카지노</h2>
                <p>아멀티 배팅 바카라의 시작</p>
                <div
                  className="join"
                  style={{
                    color: "#f26522",
                    border: "solid 3px #f26522",
                    cursor: "pointer",
                  }}
                  key={`key${info4 !== undefined ? info4.nameEn : ""}kkk`}
                  onClick={() => {
                    if (this.props.authenticated == false) {
                      confirmAlert({
                        title: "로그인",
                        message: "로그인 이후 이용가능한 기능입니다",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {
                              this.setState({ popupStatuses: popupView.none });
                            },
                          },
                        ],
                      });
                      return;
                    }

                    if ((info4 !== undefined ? info4.used : "") === "y") {
                      this.handleOpenSlot(
                        info4 !== undefined ? info4.code : "",
                        info4 !== undefined ? info4.gameCompany : ""
                      );
                    } else {
                      confirmAlert({
                        title: "점검중",
                        message: "현재 해당게임은 점검중입니다 .",
                        buttons: [
                          {
                            label: "확인",
                            onClick: () => {},
                          },
                        ],
                      });
                    }
                  }}
                >
                  Join Oriental
                </div>
              </a>
              <img src="/img/slide4.jpg" className="img" />
            </li>
          </ul>
        </div>
      </>
    );
  };

  RenderCasinoGame = () => {
    return (
      <>
        <ul className="game">
          {this.state.casionos.map((info: any, index: any) => (
            <li
              key={`key${info.nameEn}kkk`}
              onClick={() => {
                if (this.props.authenticated == false) {
                  confirmAlert({
                    title: "로그인",
                    message: "로그인 이후 이용가능한 기능입니다",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {
                          this.setState({
                            popupStatuses: popupView.none,
                          });
                        },
                      },
                    ],
                  });
                  return;
                }

                if (info.used === "y") {
                  this.handleOpenSlot(info.code, info.gameCompany);
                } else {
                  confirmAlert({
                    title: "점검중",
                    message: "현재 해당게임은 점검중입니다 .",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => {},
                      },
                    ],
                  });
                }
              }}
            >
              <div className="con">
                <img src={info.mobileImg} />
                <div className="title">
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "25px",
                      fontWeight: "bold",
                      marginTop: "120px",
                    }}
                  >
                    {info.nameKo}
                  </p>
                </div>
                <a href="javascript:;">
                  <img src="/img/btn_game.png" />
                </a>
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  };

  RenderGame = () => {
    if (this.state.mode !== gameView.game) {
      return <></>;
    }
    return (
      <>
        <main className="page-content">
          <section className="company-section">
            <div className="container slot-container active"></div>
          </section>
        </main>
      </>
    );
  };

  RenderNotice = () => {
    let notices: any = [];
    let widhdraws = this.state.widhdraws;

    this.state.notices.map((info: any, index: any) => {
      if (index < 4) {
        notices.push(info);
      }
    });

    return (
      <>
        <div
          className="notice ani"
          animate-effect="zoomIn"
          animate-duration="0.3s"
          animate-delay="0.1s"
        >
          <h2>공지사항</h2>
          <ul>
            {notices.map((info: any, index: any) => (
              <li>
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setState({ popupStatuses: popupView.notice }); //공지사항
                  }}
                >
                  [공지] {info.title}
                </a>
                <span className="date" style={{ color: "#666" }}>
                  {ConvertDate(info.regdate)}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  render() {
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      if (this.state.popupStatuses === popupView.game) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }

        return (
          <Companylist
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
            gameCode={this.state.gameCode}
            gameName={this.state.gameName}
          ></Companylist>
        );
      }

      if (this.state.popupStatuses === popupView.notice) {
        if (this.props.authenticated === false) {
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({ popupStatuses: popupView.none });
                },
              },
            ],
          });
          return;
        }
        return (
          <Notice
            handleClose={this.handleClosePopup}
            handleChange={this.handleChangePopup}
          ></Notice>
        );
      }

      return <div></div>;
    };

    //

    return (
      <>
        {this.RenderCasinoMobile()}
        {this.RenderCasino()}

        <div id="body_wrap">
          <div id="game_wrap">{this.RenderCasinoGame()}</div>

          <div id="section" className="section section1">
            <div className="customer">
              {this.RenderNotice()}

              <div
                className="sns ani"
                animate-effect="zoomIn"
                animate-duration="0.3s"
                animate-delay="0.2s"
              >
                <ul>
                  <li>
                    <img src="/img/msg_kakaotalk.png" />
                    카카오톡: gcasino
                  </li>
                  <li>
                    <img src="/img/msg_telegram.png" />
                    텔레그램: @gcasino
                  </li>
                  <li>
                    <img src="/img/msg_skype.png" />
                    고객센터:070-8098-2615
                  </li>
                  <li>
                    <img src="/img/msg_tel.png" />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="section" className="section section2">
            <ul
              className="support ani"
              animate-effect="zoomIn"
              animate-duration="0.3s"
              animate-delay="0.1s"
            >
              <li>
                <div className="con">
                  <strong
                    className="bg1"
                    style={{ height: "80px", paddingTop: "20px" }}
                  >
                    <img src="/img/intro_title1.png" />
                  </strong>
                  <p>
                    게임을 플레이하기 위하여 원하시는 입금액을 신청하시고 입금
                    확인 후 자동처리 되어 통합칩이 충전됩니다.
                    <br />
                    통합칩을 이용하여 럭키게임 되시 길 기원합니다.
                  </p>
                </div>
              </li>
              <li>
                <div className="con">
                  <strong
                    className="bg2"
                    style={{ height: "80px", paddingTop: "20px" }}
                  >
                    <img src="/img/intro_title2.png" />
                  </strong>
                  <p>
                    축하드립니다.
                    <br />
                    통합칩을 출금하기 위하여 원하시는 금액을 출금 신청 하시면
                    관리자 확인 후 처리됩니다.
                  </p>
                </div>
              </li>
{/* 
              <li>
                <div className="con">
                  <strong
                    className="bg3"
                    style={{ height: "80px", paddingTop: "20px" }}
                  >
                    <img src="/img/intro_title3.png" />
                  </strong>
                  <p>고객님의 소중한 포인트를 통합칩으로 전환 할 수있습니다.</p>
                </div>
              </li>

              <li>
                <div className="con">
                  <strong
                    className="bg4"
                    style={{ height: "80px", paddingTop: "20px" }}
                  >
                    <img src="/img/intro_title4.png" />
                  </strong>
                  <p>
                    보유하신 통합칩을 원하시는 카지노로 이동 후 플레이 하시면
                    됩니다.
                    <br />
                    카지노에서 게임머칩을 통합칩으로 다시 옴길시에도
                    이용해주세요.
                  </p>
                </div>
              </li> */}
            </ul>
          </div>
        </div>

        {RenderPopup()}
      </>
    );
  }
}
