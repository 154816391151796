import React, { Component } from "react";
import Popup from "reactjs-popup";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ConverMoeny } from "../utility/help";
import { BalanceService } from "../service/balance.service";
import { ShareMenu } from "./share_menu";

interface Props {
  user: any;
  handleClose: () => any;
  handleChange: (viewName: string) => any;
  updateUser: () => any;
}

interface State {
  balance: string;
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",

  reg = "reg",
  login = "login",
  comp = "comp",
}

export class Comp extends Component<Props, State> {
  balanceService = new BalanceService();
  constructor(props: Props) {
    super(props);
    this.state = { balance: "0" };
  }

  handleRollingToBalance = () => {
    this.balanceService.rolingToBalance(this.state.balance).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "콤프 전환",
          message: "콤프 전환을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.reload()
              },
            },
          ],
        });
        return;
      } else if (data.status === "balance") {
        confirmAlert({
          title: "콤프 전환",
          message: "1.000원 이하는 콤프전환이 안됩니다 전환금액확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "low_point") {
        confirmAlert({
          title: "콤프 전환",
          message: "보유하신 콤프금액이 부족합니다 .",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else if (data.status === "pass") {
        confirmAlert({
          title: "콤프 전환",
          message: "환전 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "콤프",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    console.log(this.props.user)
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
        {(close) => (
          <>
            <div
              className="b-modal __b-popup1__"
              style={{
                // backgroundColor: "rgb(0, 0, 0)",
                position: "fixed",
                inset: "0px",
                opacity: "0.7",
                cursor: "pointer",
              }}
            ></div>

            <div
              style={{
                //width: "1000px",
                height: "760px",
                background: "#151618",
                color: "#ffffff",
                fontFamily: "'Open Sans', helvetica, arial, sans-serif",
                zIndex: 1000001,
                position: "absolute",
                left: "50%",
                //marginLeft: "-500px",
              }}
              className="container"
            >
              <div className="row" style={{ marginTop: "60px" }}>
                <div className="panel-primary">
                  <div className="panel-body">
                    <form
                      name="joinForm"
                      id="joinForm"
                      className="form-horizontal"
                    >
                      <h2
                        style={{
                          color: "rgb(248, 186, 65)",
                          fontSize: "30px",
                          fontWeight: 400,
                        }}
                      >
                        콤프
                      </h2>
                      <div className="hr-line-dashed"></div>

                      <div className="form-group row">
                        <div className="col-xs-12">
                          <p className="title">주의사항 Notice</p>
                          <p>
                            1. 콤프전환은 1000p 단위로 전환 가능합니다.
                          </p>
                        </div>
                      </div>
                      
                      <div className="form-group row">
                        <div className="col-xs-12">
                          <p
                            style={{
                              color: "rgb(248, 186, 65)",
                              fontWeight: "bold",
                            }}
                          >
                            보유머니 : {ConverMoeny(this.props.user.balance)}
                          </p>
                          <p
                            style={{
                              color: "rgb(248, 186, 65)",
                              fontWeight: "bold",
                            }}
                          >
                            콤프머니 : {ConverMoeny(this.props.user.rolling)}
                          </p>
                        </div>
                      </div>
                
                      <div className="form-group row">
                        <div className="col-xs-6">
                          <label className="control-label">신청금액</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="0"
                            value={this.state.balance}
                            onFocus={() => this.setState({ balance: "" })}
                            onChange={(e) =>
                              this.setState({
                                balance: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div
                          className="col-xs-6"
                          style={{ paddingTop: "10px" }}
                        >
                          <p>&nbsp;</p>
                          <button
                            type="button"
                            style={{ margin: "1px" }}
                            className="btn-xs btn-warning"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 10000
                                }`,
                              });
                            }}
                          >
                            1만원
                          </button>
                          <button
                            type="button"
                            style={{ margin: "1px" }}
                            className="btn-xs btn-warning"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 50000
                                }`,
                              });
                            }}
                          >
                            5만원
                          </button>
                          <button
                            type="button"
                            style={{ margin: "1px" }}
                            className="btn-xs btn-warning"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 100000
                                }`,
                              });
                            }}
                          >
                            10만원
                          </button>
                          <button
                            type="button"
                            style={{ margin: "1px" }}
                            className="btn-xs btn-warning"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 500000
                                }`,
                              });
                            }}
                          >
                            50만원
                          </button>
                          <button
                            type="button"
                            style={{ margin: "1px" }}
                            className="btn-xs btn-warning"
                            onClick={() => {
                              this.setState({
                                balance: `${
                                  Number(this.state.balance) + 1000000
                                }`,
                              });
                            }}
                          >
                            100만원
                          </button>
                          <button
                            type="button"
                            style={{ margin: "1px" }}
                            className="btn-xs btn-danger"
                            onClick={() => {
                              this.setState({ balance: "0" });
                            }}
                          >
                            정정
                          </button>
                        </div>
                      </div>

                      <div className="hr-line-dashed"></div>

                      <div className="form-group">
                        <div className="col-xs-12">
                          <button
                            className="btn col-xs-4 btn-lg btn-primary btn-block"
                            onClick={this.handleRollingToBalance}
                            style={{ background: "#f8ba41" }}
                          >
                            콤프전환
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="pop_close"
                onClick={() => {
                  this.props.handleClose();
                }}
              ></div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
