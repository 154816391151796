import React, { Component } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { UserService } from "../service/user.service";
import { ConvertDate } from "../utility/help";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  comp = "comp",
}

export enum noticeView {
  none = "none",
  list = "list",
  view = "view",
}

const CustomTableCell = styled(TableCell)`
  background: linear-gradient(#2c2c2c 0%, #1c1c1c 100%);
  color: #dddddd;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  font-family: "nanumgothic", sans-serif;
`;

const CustomTableCell2 = styled(TableCell)`
  background: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  line-height: 40px;
  padding: 0 0 0 15px;
  font-weight: bold;
`;

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props: { row: any }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.ref != null) {
    ref = row.ref.contents;
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell2 className="list_notice1">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          onClick={() => setOpen(!open)}
          style={{ color: "#ffffff" }}
          className="list_notice2"
        >
          {row.title}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          style={{ color: "#ffffff" }}
          className="list_notice2"
        >
          {ConvertDate(row.row)}
        </CustomTableCell2>
        <CustomTableCell2
          align="center"
          className="list_notice2"
        ></CustomTableCell2>
      </TableRow>
      <TableRow>
        <CustomTableCell2
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div
                style={{
                  height: "400px",
                  overflowY: "scroll",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: row.contents }}></div>
              </div>
            </Box>
          </Collapse>
        </CustomTableCell2>
      </TableRow>
    </React.Fragment>
  );
}

interface Props {
  handleClose: () => any;
  handleChange: (viewName: string) => any;
}

interface State {
  notices: any;
  mode: string;
  title: string;
  contents: string;
  date: string;
  index: string;
  page: number;
  maxCount: number;
}

export class Notice extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      notices: [],
      mode: noticeView.list,
      title: "",
      contents: "",
      date: "",
      index: "",
      page: 1,
      maxCount: 0,
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }

  handleGetNotices = () => {
    this.userService.get_user_notices().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({
          notices: data.notices,
          maxCount: data.maxCount,
        });
      }
    });
  };

  render() {
    let notices: any = [];
    let pages: any = [];
    let pageSize = 5;
    let maxCount = this.state.maxCount;
    let totPage = Math.floor(maxCount / pageSize) + 1;
    let start = (this.state.page - 1) * pageSize;
    let end = this.state.page * pageSize - 1;

    console.log("maxCount : " + this.state.maxCount);
    console.log(
      "totPage : " + totPage + ", start : " + start + ", end : " + end
    );

    this.state.notices.map((info: any, index: any) => {
      if (index >= start && index <= end) {
        info.no =
          maxCount - (start + (index - pageSize * (this.state.page - 1)));
        notices.push(info);
      }
    });

    for (let i = 1; i <= totPage; i++) {
      pages.push({ no: i });
    }

    const RenderList = () => {
      if (this.state.mode !== noticeView.list) {
        return <></>;
      }
      return (
        <>
          <table
            className="table table-hover table-bordered"
            style={{ textAlign: "right", color: "white", fontSize: "14px" }}
          >
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "20%" }}>번호</th>
                <th style={{ textAlign: "center", width: "60%" }}>제목</th>
                <th style={{ textAlign: "center", width: "20%" }}>등록일</th>
              </tr>

              {notices.map((info: any, index: any) => (
                <>
                  <tr>
                    <td style={{ textAlign: "center" }}>{info.no}</td>
                    <td style={{ textAlign: "left", color: "#337ab7" }}>
                      <a
                        style={{ color: "#337ab7" }}
                        onClick={() => {
                          this.setState({
                            title: info.title,
                            contents: info.contents,
                            date: info.regdate,
                            index: info.no,
                            mode: noticeView.view,
                          });
                        }}
                      >
                        {info.title}
                      </a>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {ConvertDate(info.regdate)}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          <div className="hr-line-dashed"></div>

          <div className="col-xs-5"></div>
          <div className="col-xs-5">
            <table
              cellPadding={0}
              cellSpacing={0}
              style={{ border: "0" }}
              className="main_1"
            >
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td>
                    <table
                      cellPadding={0}
                      cellSpacing={0}
                      style={{ border: "0" }}
                      className="main_1"
                    >
                      <tbody>
                        <tr style={{ textAlign: "center" }}>
                          <td style={{ width: "20px" }}>
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.setState({
                                  page: 1,
                                });
                              }}
                            >
                              <img
                                src="/img/notice_icon_1.gif"
                                style={{ width: "14px", height: "15px" }}
                              />
                            </a>
                          </td>

                          {pages.map((page: any) => {
                            if (page.no === this.state.page) {
                              return (
                                <>
                                  <td className="text_1 paingTd">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        paddingRight: "3px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          page: page.no,
                                        });
                                      }}
                                    >
                                      <b> {page.no} </b>
                                    </span>
                                  </td>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <td className="text_1 paingTd">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        paddingRight: "3px",
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          page: page.no,
                                        });
                                      }}
                                    >
                                      {page.no}
                                    </span>
                                  </td>
                                </>
                              );
                            }
                          })}

                          <td style={{ width: "20px" }}>
                            <a
                              href="javascript:;"
                              onClick={() => {
                                this.setState({
                                  page: totPage,
                                });
                              }}
                            >
                              <img
                                src="/img/notice_icon_4.gif"
                                style={{ width: "14px", height: "15px" }}
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    };

    const RenderView = () => {
      if (this.state.mode !== noticeView.view) {
        return <></>;
      }
      return (
        <>
          {/* 상세 */}
          <table
            className="table table-bordered"
            style={{ textAlign: "right", color: "white", fontSize: "14px" }}
          >
            <tbody>
              <tr>
                <th style={{ textAlign: "center", width: "20%" }}>번호</th>
                <th style={{ textAlign: "center", width: "60%" }}>제목</th>
                <th style={{ textAlign: "center", width: "20%" }}>등록일</th>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>{this.state.index}</td>
                <td style={{ textAlign: "center" }}>{this.state.title}</td>
                <td style={{ textAlign: "center" }}>
                  {ConvertDate(this.state.date)}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left", height: "400px" }} colSpan={3}>
                  <div
                    style={{ overflow: "auto", width: "100%", height: "100%" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: this.state.contents }}
                    ></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="hr-line-dashed"></div>

          <div>
            <button
              className="btn col-xs-4 btn-lg btn-primary btn-block"
              onClick={() => {
                this.setState({
                  mode: noticeView.list,
                });
              }}
            >
              목록
            </button>
          </div>
        </>
      );
    };

    return (
      <Popup
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
        onClose={()=> this.props.handleClose()}
      >
        {(close) => (
          <>
            {/* <div
              className="b-modal __b-popup1__"
              style={{
                position: "fixed",
                inset: "0px",
                opacity: "0.7",
                cursor: "pointer",
              }}
            ></div> */}

            <div
              style={{
                //width: "1000px",
                height: "760px",
                background: "#151618",
                color: "#ffffff",
                fontFamily: "'Open Sans', helvetica, arial, sans-serif",
                zIndex: 1000001,
                position: "absolute",
                left: "50%",
                //marginLeft: "-500px",
              }}
              className="container"
            >
              <div className="row" style={{ marginTop: "60px" }}>
                <div className="panel-primary">
                  <div className="panel-body">
                    <form
                      name="joinForm"
                      id="joinForm"
                      className="form-horizontal"
                    >
                      <h2
                        style={{
                          color: "rgb(248, 186, 65)",
                          fontSize: "30px",
                          fontWeight: 400,
                        }}
                      >
                        공지사항
                      </h2>
                      <div className="hr-line-dashed"></div>

                      {RenderList()}
                      {RenderView()}
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="pop_close"
                onClick={() => {
                  this.props.handleClose();
                }}
              ></div>
            </div>
          </>
        )}
      </Popup>
    );
  }
}
